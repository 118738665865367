var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'EducationalGroupStudentInfo',"size":"lg","headerText":_vm.$t('EducationalGroupStudents.data'),"headerIcon":_vm.educationalGroupStudent.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupStudent.studentUserInfoDate
          ? _vm.educationalGroupStudent.studentUserInfoDate.userNameCurrent
          : '',"title":_vm.$t('students.name'),"imgName":'students.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupStudent.educationalGroupInfoData
          ? _vm.educationalGroupStudent.educationalGroupInfoData
              .educationalGroupNameCurrent
          : '',"title":_vm.$t('EducationalGroups.name'),"imgName":'EducationalGroups.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupStudent.educationalJoiningApplicationInfoDate
          ? _vm.educationalGroupStudent.educationalJoiningApplicationInfoDate
              .educationalJoiningApplicationNameCurrent
          : '',"title":_vm.$t('EducationalJoiningApplications.name'),"imgName":'EducationalJoiningApplications.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
          _vm.educationalGroupStudent.creationDate,
          _vm.educationalGroupStudent.creationTime
        ),"title":_vm.$t('actionsData.dateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.educationalGroupStudent.certificateStatusNameCurrent,"title":_vm.$t('general.certificateStatus'),"imgName":'type.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }